import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form", {
    attrs: {
      labelCol: {
        style: "width: 85px"
      },
      form: _vm.editorForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "标题"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["title", {
        rules: [{
          required: true,
          message: "请输入banner名称!"
        }]
      }],
      expression: "[\n                        'title',\n                        {\n                            rules: [\n                                { required: true, message: '请输入banner名称!' },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      autoComplete: "off",
      size: "large",
      placeholder: "请输入",
      maxlength: "50"
    },
    on: {
      change: function change(e) {
        e.target.value = e.target.value.trim();
        return e;
      }
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "封面图"
    }
  }, [_c("UploadImg", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["picurl", {
        rules: [{
          required: true,
          message: "请上传图片"
        }],
        initialValue: ""
      }],
      expression: "[\n                        'picurl',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请上传图片',\n                                },\n                            ],\n                            initialValue: '',\n                        },\n                    ]"
    }],
    attrs: {
      listType: "picture-card",
      max: 1,
      memorySize: "200kb"
    },
    on: {
      previewImg: _vm.previewImg
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "链接"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["linkurl", {
        rules: [{
          required: true,
          message: "请输入链接地址!"
        }]
      }],
      expression: "[\n                        'linkurl',\n                        {\n                            rules: [\n                                { required: true, message: '请输入链接地址!' },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      autoComplete: "off",
      size: "large",
      placeholder: "请输入",
      maxlength: "50"
    },
    on: {
      change: function change(e) {
        e.target.value = e.target.value.trim();
        return e;
      }
    }
  })], 1)], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1)], 1), _c("a-modal", {
    attrs: {
      visible: _vm.preVisible,
      width: "460px",
      centered: true,
      maskClosable: false,
      closable: true,
      destroyOnClose: true,
      footer: null,
      title: "预览"
    },
    on: {
      cancel: function cancel($event) {
        _vm.preVisible = false;
      }
    }
  }, [_c("div", {
    staticClass: "preExposure-box"
  }, [_c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.content)
    }
  })])]), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.handleCancel
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };